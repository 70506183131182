import { Avatar, Box, Card, Grid, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { Icons } from "src/_mock/assets/icons/Icons";
import Iconify from "src/components/iconify";
import { useAppDispatch } from "src/redux/hooks";
import { updateShowSendModal } from "src/redux/slices/modalsSlice";
import { Inventory } from "src/types/Inventory";

interface IPointsSectionProps {
    point: Inventory.IPoint;
}

/* 
    UI Component for showing the point item the user owns. 
    * Shows the name,quantity,image
    * Intractable - send

*/
export default function InventoryPointItem({ point }: IPointsSectionProps) {

    const theme = useTheme();





    const dispatch = useAppDispatch();

    const handleInspectClick = () => {
        // dispatch(showGiftoinInfo({
        // 	giftoinId
        // }));
    }

    const handleSend = () => {
        // console.log(giftoinId)
        dispatch(updateShowSendModal({
            show: true,
            type: 'point',
            point,
        }));
        // dispatch(showGiftoinInfo({ giftoinId }));
        // dispatch(updateShowSendModal(true));
    }

    const canSend = point.quantity > 0;

    // return <Stack direction='column' width='100%' spacing={1}>
    return <Stack component={Card} width='100%' height="100%" spacing={2} p={4}>
        <Box sx={{ width: '100%', height: { xs: 120, md: 240 }, borderRadius: 1, justifyContent: 'center', alignItems: 'center', display: 'flex', backgroundImage: 'url(/assets/background/overlay_3.jpg)', border: `2px solid ${theme.palette.background.neutral}` }} >
            <Avatar sx={{ width: 120, height: 120 }} src={point.image} alt={`${point.name}_thumbnail`} />

        </Box>
        <Typography variant="subtitle1">{point.name}</Typography>
        <Typography variant='body2' sx={{ color: 'text.secondary' }} noWrap>{point.quantity}</Typography>
        {canSend && <Stack direction='row' spacing={1} display='flex' alignItems='center' justifyContent='center'>
            <IconButton color="primary" onClick={() => handleSend()} aria-label="send">
                <Iconify icon={Icons.DefaultIcons.send} />
            </IconButton>
        </Stack>}

    </Stack>

}