import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState, forwardRef } from "react";
import { TokenTypes, useGetAssetsPointsQuery } from "src/api/assetApi";
import { useAppSelector } from "src/redux/store";

import type { TransitionProps } from '@mui/material/transitions';

import { m } from 'framer-motion';
import { MotionContainer, varBounce } from "src/components/animate";

import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { useBoolean } from 'src/hooks/use-boolean';
import { QuestTypes } from 'src/types/Quest';
import { DefaultIcons } from 'src/_mock/assets';
import Iconify from 'src/components/iconify';
import Markdown from 'src/components/markdown/markdown';
import { LoadingButton } from '@mui/lab';
import { useGetManyQuery } from 'src/api/giftoinApi';
import { wait } from 'src/utils/axios';
import { Giftoin } from 'src/types/Giftoin';
import { useGetQuestsQuery } from 'src/api/loyaltyApi';
import InventoryPointItem from "src/sections/wallet/wallet-preview/points/point-item";

interface IBalance extends TokenTypes.IPoint {
    id: string;
    quantity: number;
    name: string;
    image: string;
}

export default function PointsBalances() {
    const accountId = useAppSelector(state => state.resellerSettings.resellerId);
    const { data: points } = useGetAssetsPointsQuery({ accountId });

    const inventoryPoints = useAppSelector(state => state.inventory.points);

    const [balances, setBalances] = useState<IBalance[]>([]);


    useEffect(() => {
        const newBalances: IBalance[] = points ?
            points.map(c => {
                const inventoryCoin = inventoryPoints.find(i => i.id === c.id);
                return { ...c, quantity: inventoryCoin?.quantity ?? 0 }

            })
            : [];

        setBalances(newBalances);
    }, [points, inventoryPoints])



    return <>
        {balances && balances.length > 0 &&
            <Stack component={Card} spacing={2} sx={{ pl: 2, pr: 2, pt: 1, pb: 1, backgroundColor: (theme) => theme.palette.background.neutral }}>
                {/* <Grid container display='flex' justifyContent='center' > */}
                <Grid container width='100%' alignItems='center' justifyContent='center' rowGap={{ md: 2 }}>

                    {balances.map((coin, index) =>
                        <Grid item display='flex' xs={6} md={12} alignItems='center' justifyContent='center'>
                            <ViewBalanceDialog balance={coin} key={index} />
                        </Grid>
                    )}
                </Grid>

                {/* </Grid> */}


            </Stack>
        }
    </>

}









// import Image from '../../image';

// ----------------------------------------------------------------------

export const Transition = forwardRef(
    (
        props: TransitionProps & {
            children: React.ReactElement;
        },
        ref: React.Ref<unknown>
    ) => <Slide direction="down" ref={ref} {...props} />
);


interface IProps {
    // welcomeQuest: QuestTypes.IWelcomeQuest;
    balance: IBalance;
}

export function ViewBalanceDialog({ balance }: IProps) {



    const dialog = useBoolean(false);



    return (
        <div>
            <MotionContainer>
                <m.div variants={varBounce().in}>


                    <Stack onClick={dialog.onTrue} sx={{ cursor: "pointer" }} spacing={1} display='flex' alignItems='center' direction='row' >
                        <Typography variant="subtitle1">
                            {balance.quantity}
                        </Typography>
                        <Box width={32} component="img" alt={balance.name} src={balance.image ?? ""} sx={{
                            height: '100%',

                        }} />
                    </Stack>

                </m.div>
            </MotionContainer>

            <Dialog
                keepMounted
                open={dialog.value}
                TransitionComponent={Transition}
                onClose={dialog.onFalse}
                maxWidth="xl"
            >
                {/* <DialogTitle>{balance.name}</DialogTitle> */}

                <DialogContent sx={{ color: 'text.secondary', p: 1 }}>
                    <Box height={1} width={320}>

                        <InventoryPointItem

                            point={{
                                ...balance,
                                createdAt: Date.now(),
                                updatedAt: Date.now(),
                                decimals: 0,
                                scope: 'RESELLER'
                            }}
                        />
                    </Box>
                    {/* TODO: Add the points component here */}

                </DialogContent>

                <DialogActions>
                    <Button fullWidth variant="contained" onClick={dialog.onFalse}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}





